* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: black; 
  text-decoration: none; 
}

a:hover {
  text-decoration: none; 
}

body {
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  background-color: #fff;
  display: flex;
  justify-content: center;
  margin: 0;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
}

.association-name {
  font-weight: bold;
  font-size: 1.5rem;
  margin-top: 10px;
  text-align: center;
}

.menu-button {
  background: none;
  border: none;
  font-size: 30px;
  color: #1b1a1a;
  cursor: pointer;
}

.menu-button:active {
  background: transparent;
}

.menu {
  position: absolute;
  top: 100px;
  right: 50px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 180px;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu ul li {
  margin-bottom: 8px;
}

.menu ul li button {
  background: none;
  border: none;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 8px 10px;
  box-sizing: border-box;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: 1px solid black;
  background-color: #ffffff;
  width: auto;
  min-width: 150px;
  text-align: center;
  color: black;
}

.menu ul li button:hover {
  background-color: #f0f0f0;
}

@media (max-width: 600px) {
  .signup-container {
    padding-top: 60px; 
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 90px; 
}
  .menu-container {
    padding: 10px;
  }

  .association-name {
    font-size: 1.2rem;
  }

  .menu-button {
    font-size: 25px;
  }

  .menu {
    top: 50px;
    width: 150px;
  }

  .menu ul li button {
    font-size: 12px;
    padding: 6px 8px;
  }

  .filter-buttons {
    display: flex;
    max-width: 60%;
    overflow-y: scroll;
  }

  .filter-buttons button{
    font-size: 12px;
    width: auto;
  }

  .card-container {
    padding-left: 25%;
  }

  .event-summary {
    width: 60%;
    overflow-y: scroll;
    margin-left: 15%;
    margin-right: 15%;
  }

  .event-summary button {
    padding: 0;
    font-size: 8px;
  }
}

@media (max-width: 480px) {
  .association-name {
    font-size: 1rem;
  }

  .menu-button {
    font-size: 20px;
  }
}

.filter-buttons {
  margin-top: 15%;
  margin-bottom: 5%;
}

.homepage-container, .signup-container, .login-container, .welcome-container, .event-container, .account-settings-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 70px;
  box-sizing: border-box;
}

.homepage-container {
  height: 90vh;
}

.signup-container, .login-container, .welcome-container {
  /* height: 80vh; */
  padding: 50px;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
  gap: 10px;
  margin-top: 50px;
}

.button-container button {
  font-size: 14px;
  border-radius: 15px;
  padding: 15px 3px;
  margin-bottom: 30px;
}


.form-group {
  margin-bottom: 15px;
}

.form-container {
  background-color: #fff;
  padding: 15px;
  padding-bottom: 5px;
  border-radius: 12px;
  margin-top: 30%;
  width: 350px;
  text-align: left;
}

.form-container h3 {
  margin-bottom: 50px;
}

label {
  display: block;
  font-weight: 10;
  margin-top: 8px;
}

input {
  width: 100%;
  max-width: 500px;
  padding: 8px;
  height: 35px;
  border: 1px solid #252424c2;
  margin-top: 8px;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.form-row .form-group {
  flex: 1;
}

.search-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 40px;
}

.search-bar {
  width: calc(100% - 40px);
  height: 40px;
  max-width: 1200px;
  padding: 10px;
  font-size: 16px;
  border: none;
  background-color: #eeeded;
  border-radius: 4px;
  margin-right: 10px;
}

.search-icon {
  font-size: 20px;
  color: black;
}

.date-text {
  font-size: 24px;
  color: #333;
  padding: 10px 20px;
  margin-left: 120px;
  margin-top: 80px;
}

.attendees {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  font-size: 50px;
  text-align: center;
  margin-bottom: 50px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 15px;
}

.button-container2 {
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: right;
  align-items: right;
  margin-right: 80px;
}

.small-button {
  padding: 5px 10px;
  font-size: 15px;
  cursor: pointer;
  background-color: white;
  color: rgb(19, 18, 18);
  transition: background-color 0.3s ease;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 40px;
  padding-bottom: 60px;
}

.card {
  flex: 1 1 100%;
  max-width: 18rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 16px;
  position: unset;
}

.card-body {
  padding: 16px;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

.card-text {
  font-size: 1rem;
  margin-bottom: 4px;
}

.summary-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 100px;
  align-items: center;
}

@media (min-width: 576px) {
  .card {
    flex: 1 1 calc(50% - 16px);
  }
}

@media (min-width: 768px) {
  .card {
    flex: 1 1 calc(33.333% - 16px);
  }
}

@media (min-width: 992px) {
  .card {
    flex: 1 1 calc(25% - 16px);
  }
}

.new-event-button {
  border-radius: 5px;
  padding: 5px 15px;
  align-content: center;
}

.delete-event-button {
  background-color: red;
  color: white;
  border-color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
  margin-bottom: 5px;
}

.event-details {
  margin-top: 25px;
  text-align: left;
}

.event-summary {
  margin-top: 15%;
}

.see-attendees-button {
  background-color: green;
  border-color: white;
  color: white;
  border-radius: 5px;
  padding: 5px;
  font-size: 12px;
}

.error-message {
  color: red;
  margin-top: 10px;
}
